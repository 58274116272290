<template>
    <BT-Blade-Items
        addBladeName="customer-invoice"
        bladeName="customer-invoices"
        :bladesData="bladesData"
        :canAdd="false"
        canDelete
        canSearchLocal
        :dependantBlades="[{ bladeName: 'customer-invoice' }]"
        :getParams="item => { return { includeDetails: false, customerIDs: item.data == null ? null : item.data.customerID }}"
        :headers="[
            { text: 'INV#', value: 'invoiceNumber', subtitle: 2, prefix: 'INV#', searchable: true }, 
            { text: 'Issue Date', value: 'issuedOn', textFilter: 'toShortDate' },
            { text: 'Due Date', value: 'dueOn', textFilter: 'toShortDate', subtitle: 1, prefix: 'Due: ' }, 
            { text: 'Customer', value: 'buyer.companyName', title: 1, searchable: true },
            { text: 'Amount', value: 'amountTotal', textFilter: 'toCurrency' },
            { text: 'Status', value: 'isPaid', display: true }]"
        :itemProperties="['ID', 'invoiceNumber', 'customerOrderNumber', 'purchaseOrderNumber', 'amountTotal', 'issuedOn', 'dueOn', 'buyer', 'subTotal', 'taxTotal', 'isPaid']"
        :loading="isLoading"
        navigation="customer-invoices"
        :refreshToggle="refreshToggle"
        :searchProps="['customerOrderNumber', 'purchaseOrderNumber', 'invoiceNumber']"
        syncNavigation="customer-invoice-syncing"
        title="Customer Invoices"
        useServerPagination>
            <template v-slot:settings>
                <v-divider />
                <v-subheader>Actions  <v-spacer />
                    <v-btn small icon :to="{ name: 'order-invoicing-actions' }" title="View Past Actions">
                        <v-icon small>mdi-history</v-icon>
                    </v-btn>
                </v-subheader>
                <BT-Entity
                    v-if="$canView('invoicing-settings')"
                    ignoreID
                    inline
                    navigation="invoicing-settings"
                    single>
                    <template v-slot="{ item, save }">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>Push To 3rd Party</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row dense>
                                    <v-btn 
                                        icon 
                                        small 
                                        @click="() => { item.invoiceSyncAsDrafts = !item.invoiceSyncAsDrafts; save(); }" 
                                        :class="item.invoiceSyncAsDrafts ? 'success--text' : null"
                                        title="Send As Drafts">
                                        <v-icon>mdi-new-box</v-icon>
                                    </v-btn>
                                    <BT-Menu-Select
                                        icon="mdi-account-network"
                                        item-text="id"
                                        item-value="id"
                                        navigation="external-party-options"
                                        v-model="item.invoicePushExternalParty"
                                        @change="save" />
                                    <BT-Menu-Automation 
                                        v-model="item.invoicePushAutomation" 
                                        dailyOnly
                                        @change="save" />
                                    <v-btn icon small @click="pushToExternalParty" title="Now">
                                        <v-icon small class="mx-1">mdi-motion-play-outline</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>Check For Paid Invoices</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon small @click="checkForPaidInvoices" title="Now">
                                    <v-icon small class="mx-1">mdi-motion-play-outline</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </BT-Entity>
                <BT-Entity
                    v-if="$canView('company-settings')"
                    ignoreID
                    inline
                    navigation="company-settings"
                    single>
                    <template v-slot="{ item, save }">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>Email Unpaid Invoice Reminders</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row dense>
                                    <BT-Menu-Automation
                                        v-model="item.invoiceReminderAutomation"
                                        isLargeRange
                                        @change="save" />
                                    <v-btn icon small @click="emailInvoiceReminders" title="Now">
                                        <v-icon small class="mx-1">mdi-motion-play-outline</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </BT-Entity>
                <v-divider />
                <v-subheader>Settings</v-subheader>
                <BT-Entity
                    v-if="$canView('company-settings')"
                    ignoreID
                    inline
                    navigation="company-settings"
                    single>
                    <template v-slot="{ item, save }">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>Receive Invoice Updates From Xero</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-switch v-model="item.useSupplierXeroWebhooks" color="success" @change="save" />
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </BT-Entity>
                <BT-Entity
                    v-if="$canView('supplier-settings')"
                    ignoreID
                    inline
                    navigation="supplier-settings"
                    single>
                    <template v-slot="{ item, save }">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>Invoice Numbering</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <BT-Toggle-Trigger-Icon
                                    v-model="item.invoiceNumberTrigger"
                                    defaultValue="Manual"
                                    :toggleOptions="[
                                        { text: 'Manual', value: 'Manual', icon: 'mdi-boom-gate' },
                                        { text: 'Auto', value: 'Auto', icon: 'mdi-boom-gate-up' },
                                        { text: 'Use Customer Order Numbers', value: 'OrderNumber', icon: 'mdi-content-copy' }]"
                                    @change="save" />
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-if="item.invoiceNumberTrigger == 'Auto'">
                            <v-list-item-content>
                                <v-list-item-subtitle>Invoice Number Prefix</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-edit-dialog>
                                    <span :class="item.invoicePrefix == null ? 'grey--text lighten-2' : ''">{{ item.invoicePrefix || '(prefix)' }}</span>
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="item.invoicePrefix"
                                            @change="save"
                                            label="Prefix"
                                            placeholder="Prefix"
                                            prepend-icon="#"
                                            singleLine
                                            hideDetails />
                                    </template>
                                </v-edit-dialog>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-if="item.invoiceNumberTrigger == 'Auto'">
                            <v-list-item-content>
                                <v-list-item-subtitle>Last Invoice Number</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-edit-dialog v-if="item.invoiceNumberTrigger == 'Auto'">
                                    <span>#{{ item.invoicePrefix }}{{ item.lastInvoiceNumber }}</span>
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model.number="item.lastInvoiceNumber"
                                            @change="save"
                                            label="Last Invoice Number"
                                            :prepend-icon="'#' + (item.invoicePrefix || '')"
                                            singleLine
                                            hideDetails
                                            type="number" />
                                    </template>
                                </v-edit-dialog>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </BT-Entity>
                <BT-Entity
                    ignoreID
                    inline
                    navigation="invoicing-settings"
                    single>
                    <template v-slot="{ item, save }">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>Email Invoice Notifications To Me</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-edit-dialog>
                                    <v-btn small icon :title="item.emailAddressesAsSupplier">
                                        <v-icon small :class="item.emailAddressesAsSupplier == null || item.emailAddressesAsSupplier.length == 0 ? '' : 'success--text'">mdi-email</v-icon>
                                    </v-btn>
                                    <template v-slot:input>
                                        <BT-Tags 
                                            v-model="item.emailAddressesAsSupplier"
                                            @change="save" />
                                    </template>
                                </v-edit-dialog>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>Email New Invoices</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <BT-Toggle-Trigger-Icon
                                    v-model="item.emailNewInvoices"
                                    :defaultValue="false"
                                    :successValue="true"
                                    :toggleOptions="[
                                        { text: 'Off', value: false, icon: 'mdi-boom-gate' },
                                        { text: 'On', value: true, icon: 'mdi-boom-gate-up' }]"
                                    @change="save" />
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </BT-Entity>
            </template>
            <template v-slot:actionsRight>
                <v-btn
                    small
                    icon
                    @click="integrationToggle = !integrationToggle">
                    <v-icon small>mdi-connection</v-icon>
                </v-btn>
            </template>

            <template slot="isPaid" slot-scope="{ item }">
                {{ item.isPaid ? 'Paid' : 'Unpaid' }}
            </template>

            <template slot="actions">
                <BT-Snack v-model="msg" />
            </template>

            <template v-slot:bottom>
                <BT-Sidebar-External-Integration
                    navigation="customer-invoices"
                    :showToggle="integrationToggle"
                    sidebarLabel="Sync With"
                    syncNavigation="customer-invoice-syncing">
                    <template v-slot:listItem="{ item }">
                        <v-list-item-title>{{ item.buyer.companyName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.invoiceNumber }}</v-list-item-subtitle>
                    </template>
                </BT-Sidebar-External-Integration>
            </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Customer-Invoices-Blade',
    components: {
        BTMenuSelect: () => import('~components/BT-Menu-Select.vue'),
        BTMenuAutomation: () => import('~components/BT-Menu-Automation.vue'),
        BTToggleTriggerIcon: () => import('~components/BT-Toggle-Trigger-Icon.vue'),
        BTSidebarExternalIntegration: () => import('~components/BT-Sidebar-External-Integration.vue'),
    },
    data: function() {
        return {
            integrationToggle: false,
            isLoading: false,
            msg: null,
            refreshToggle: false
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        async emailInvoiceReminders() {
            this.isLoading = true;

            try {
                await this.$BlitzIt.store.post('order-invoicing-actions', { functionName: 'InvoiceReminders' });
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        async pushToExternalParty() {
            this.isLoading = true;

            try {
                await this.$BlitzIt.store.post('order-invoicing-actions', { functionName: 'PushInvoices' });
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        async checkForPaidInvoices() {
            this.isLoading = true;

            try {
                await this.$BlitzIt.store.post('order-invoicing-actions', { functionName: 'UpdatePaidInvoices' });
                this.refreshToggle = !this.refreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }
        },
    }
}
</script>