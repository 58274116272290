var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"customer-invoice","bladeName":"customer-invoices","bladesData":_vm.bladesData,"canAdd":false,"canDelete":"","canSearchLocal":"","dependantBlades":[{ bladeName: 'customer-invoice' }],"getParams":function (item) { return { includeDetails: false, customerIDs: item.data == null ? null : item.data.customerID }},"headers":[
        { text: 'INV#', value: 'invoiceNumber', subtitle: 2, prefix: 'INV#', searchable: true }, 
        { text: 'Issue Date', value: 'issuedOn', textFilter: 'toShortDate' },
        { text: 'Due Date', value: 'dueOn', textFilter: 'toShortDate', subtitle: 1, prefix: 'Due: ' }, 
        { text: 'Customer', value: 'buyer.companyName', title: 1, searchable: true },
        { text: 'Amount', value: 'amountTotal', textFilter: 'toCurrency' },
        { text: 'Status', value: 'isPaid', display: true }],"itemProperties":['ID', 'invoiceNumber', 'customerOrderNumber', 'purchaseOrderNumber', 'amountTotal', 'issuedOn', 'dueOn', 'buyer', 'subTotal', 'taxTotal', 'isPaid'],"loading":_vm.isLoading,"navigation":"customer-invoices","refreshToggle":_vm.refreshToggle,"searchProps":['customerOrderNumber', 'purchaseOrderNumber', 'invoiceNumber'],"syncNavigation":"customer-invoice-syncing","title":"Customer Invoices","useServerPagination":""},scopedSlots:_vm._u([{key:"settings",fn:function(){return [_c('v-divider'),_c('v-subheader',[_vm._v("Actions "),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","icon":"","to":{ name: 'order-invoicing-actions' },"title":"View Past Actions"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-history")])],1)],1),(_vm.$canView('invoicing-settings'))?_c('BT-Entity',{attrs:{"ignoreID":"","inline":"","navigation":"invoicing-settings","single":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
        var save = ref.save;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Push To 3rd Party")])],1),_c('v-list-item-action',[_c('v-row',{attrs:{"dense":""}},[_c('v-btn',{class:item.invoiceSyncAsDrafts ? 'success--text' : null,attrs:{"icon":"","small":"","title":"Send As Drafts"},on:{"click":function () { item.invoiceSyncAsDrafts = !item.invoiceSyncAsDrafts; save(); }}},[_c('v-icon',[_vm._v("mdi-new-box")])],1),_c('BT-Menu-Select',{attrs:{"icon":"mdi-account-network","item-text":"id","item-value":"id","navigation":"external-party-options"},on:{"change":save},model:{value:(item.invoicePushExternalParty),callback:function ($$v) {_vm.$set(item, "invoicePushExternalParty", $$v)},expression:"item.invoicePushExternalParty"}}),_c('BT-Menu-Automation',{attrs:{"dailyOnly":""},on:{"change":save},model:{value:(item.invoicePushAutomation),callback:function ($$v) {_vm.$set(item, "invoicePushAutomation", $$v)},expression:"item.invoicePushAutomation"}}),_c('v-btn',{attrs:{"icon":"","small":"","title":"Now"},on:{"click":_vm.pushToExternalParty}},[_c('v-icon',{staticClass:"mx-1",attrs:{"small":""}},[_vm._v("mdi-motion-play-outline")])],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Check For Paid Invoices")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","small":"","title":"Now"},on:{"click":_vm.checkForPaidInvoices}},[_c('v-icon',{staticClass:"mx-1",attrs:{"small":""}},[_vm._v("mdi-motion-play-outline")])],1)],1)],1)]}}],null,false,92484946)}):_vm._e(),(_vm.$canView('company-settings'))?_c('BT-Entity',{attrs:{"ignoreID":"","inline":"","navigation":"company-settings","single":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
        var save = ref.save;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Email Unpaid Invoice Reminders")])],1),_c('v-list-item-action',[_c('v-row',{attrs:{"dense":""}},[_c('BT-Menu-Automation',{attrs:{"isLargeRange":""},on:{"change":save},model:{value:(item.invoiceReminderAutomation),callback:function ($$v) {_vm.$set(item, "invoiceReminderAutomation", $$v)},expression:"item.invoiceReminderAutomation"}}),_c('v-btn',{attrs:{"icon":"","small":"","title":"Now"},on:{"click":_vm.emailInvoiceReminders}},[_c('v-icon',{staticClass:"mx-1",attrs:{"small":""}},[_vm._v("mdi-motion-play-outline")])],1)],1)],1)],1)]}}],null,false,2423861170)}):_vm._e(),_c('v-divider'),_c('v-subheader',[_vm._v("Settings")]),(_vm.$canView('company-settings'))?_c('BT-Entity',{attrs:{"ignoreID":"","inline":"","navigation":"company-settings","single":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
        var save = ref.save;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Receive Invoice Updates From Xero")])],1),_c('v-list-item-action',[_c('v-switch',{attrs:{"color":"success"},on:{"change":save},model:{value:(item.useSupplierXeroWebhooks),callback:function ($$v) {_vm.$set(item, "useSupplierXeroWebhooks", $$v)},expression:"item.useSupplierXeroWebhooks"}})],1)],1)]}}],null,false,3969598843)}):_vm._e(),(_vm.$canView('supplier-settings'))?_c('BT-Entity',{attrs:{"ignoreID":"","inline":"","navigation":"supplier-settings","single":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
        var save = ref.save;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Invoice Numbering")])],1),_c('v-list-item-action',[_c('BT-Toggle-Trigger-Icon',{attrs:{"defaultValue":"Manual","toggleOptions":[
                                    { text: 'Manual', value: 'Manual', icon: 'mdi-boom-gate' },
                                    { text: 'Auto', value: 'Auto', icon: 'mdi-boom-gate-up' },
                                    { text: 'Use Customer Order Numbers', value: 'OrderNumber', icon: 'mdi-content-copy' }]},on:{"change":save},model:{value:(item.invoiceNumberTrigger),callback:function ($$v) {_vm.$set(item, "invoiceNumberTrigger", $$v)},expression:"item.invoiceNumberTrigger"}})],1)],1),(item.invoiceNumberTrigger == 'Auto')?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Invoice Number Prefix")])],1),_c('v-list-item-action',[_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Prefix","placeholder":"Prefix","prepend-icon":"#","singleLine":"","hideDetails":""},on:{"change":save},model:{value:(item.invoicePrefix),callback:function ($$v) {_vm.$set(item, "invoicePrefix", $$v)},expression:"item.invoicePrefix"}})]},proxy:true}],null,true)},[_c('span',{class:item.invoicePrefix == null ? 'grey--text lighten-2' : ''},[_vm._v(_vm._s(item.invoicePrefix || '(prefix)'))])])],1)],1):_vm._e(),(item.invoiceNumberTrigger == 'Auto')?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Last Invoice Number")])],1),_c('v-list-item-action',[(item.invoiceNumberTrigger == 'Auto')?_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Last Invoice Number","prepend-icon":'#' + (item.invoicePrefix || ''),"singleLine":"","hideDetails":"","type":"number"},on:{"change":save},model:{value:(item.lastInvoiceNumber),callback:function ($$v) {_vm.$set(item, "lastInvoiceNumber", _vm._n($$v))},expression:"item.lastInvoiceNumber"}})]},proxy:true}],null,true)},[_c('span',[_vm._v("#"+_vm._s(item.invoicePrefix)+_vm._s(item.lastInvoiceNumber))])]):_vm._e()],1)],1):_vm._e()]}}],null,false,799603358)}):_vm._e(),_c('BT-Entity',{attrs:{"ignoreID":"","inline":"","navigation":"invoicing-settings","single":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var item = ref.item;
                                    var save = ref.save;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Email Invoice Notifications To Me")])],1),_c('v-list-item-action',[_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('BT-Tags',{on:{"change":save},model:{value:(item.emailAddressesAsSupplier),callback:function ($$v) {_vm.$set(item, "emailAddressesAsSupplier", $$v)},expression:"item.emailAddressesAsSupplier"}})]},proxy:true}],null,true)},[_c('v-btn',{attrs:{"small":"","icon":"","title":item.emailAddressesAsSupplier}},[_c('v-icon',{class:item.emailAddressesAsSupplier == null || item.emailAddressesAsSupplier.length == 0 ? '' : 'success--text',attrs:{"small":""}},[_vm._v("mdi-email")])],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Email New Invoices")])],1),_c('v-list-item-action',[_c('BT-Toggle-Trigger-Icon',{attrs:{"defaultValue":false,"successValue":true,"toggleOptions":[
                                    { text: 'Off', value: false, icon: 'mdi-boom-gate' },
                                    { text: 'On', value: true, icon: 'mdi-boom-gate-up' }]},on:{"change":save},model:{value:(item.emailNewInvoices),callback:function ($$v) {_vm.$set(item, "emailNewInvoices", $$v)},expression:"item.emailNewInvoices"}})],1)],1)]}}])})]},proxy:true},{key:"actionsRight",fn:function(){return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.integrationToggle = !_vm.integrationToggle}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-connection")])],1)]},proxy:true},{key:"isPaid",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.isPaid ? 'Paid' : 'Unpaid')+" ")]}},{key:"bottom",fn:function(){return [_c('BT-Sidebar-External-Integration',{attrs:{"navigation":"customer-invoices","showToggle":_vm.integrationToggle,"sidebarLabel":"Sync With","syncNavigation":"customer-invoice-syncing"},scopedSlots:_vm._u([{key:"listItem",fn:function(ref){
                                    var item = ref.item;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.buyer.companyName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.invoiceNumber))])]}}])})]},proxy:true}])},[_c('template',{slot:"actions"},[_c('BT-Snack',{model:{value:(_vm.msg),callback:function ($$v) {_vm.msg=$$v},expression:"msg"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }